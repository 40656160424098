import { Card, Row, CardHeader, CardBody } from "reactstrap";

const CardWithGraph = (props) => {
    return (
        <Card className="shadow" style={{"minHeight": "600px"}}>
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                        <h2 className=" mb-0">{props.title}</h2>

                </Row>
            </CardHeader>
            <CardBody>
                <Row className="align-items-center">{props.graph}</Row>
            </CardBody>
        </Card>
    );
};

export default CardWithGraph;
