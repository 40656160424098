import { configureStore } from "@reduxjs/toolkit";

//import metricPlotSlice from "./metric-plots.js";
import auth0Slice from "./auth0.js";
import notificationsSlice from "./notifications.js";
import helloSlice from "./hello.js";
import qcaSlice from "./quinzena-creditos-abertos.js";
import ccSlice from "./creditos-comprados.js"
import cbSlice from "./creditos-baixados.js";
import crSlice from "./creditos-rejeitados.js";
const store = configureStore({
    reducer: {
//        metricPlots: metricPlotSlice.reducer,
        auth0: auth0Slice.reducer,
        notifications: notificationsSlice.reducer,
        //     hello: helloSlice.reducer,
        quinzenaCA: qcaSlice.reducer,
        creditosComprados: ccSlice.reducer,
        creditosBaixados: cbSlice.reducer,
        creditosRejeitados: crSlice.reducer,
    },
});

export default store;
