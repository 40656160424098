import Plot from "react-plotly.js";

const Heatmap = (data) => {
    const layout = {
        autosize: true,
        font: { family: "roboto", size: 10, color: "#333333" },
        hovermode: "x",

        legend: {
            orientation: "h",
        },
        separators: ",.",
        margin: { b: 70, l: 50, r: 0, t: 0, pad: 0, autoexpand: true },
    };

    const config = {
        responsive: true,
        displayModeBar: false,
        scrollZoom: false,
    };

    
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Plot

                data={[data.data]}
                layout={layout}
                config={config}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%" }}
            />
        </div>
    );
};

export default Heatmap;
