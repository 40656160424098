import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    cbValorLiquidoGraph: null,
    cbValorBrutoGraph: null,
    cbDailySummary: null,
    cbDailySummaryDisplay: false,
    cbSummaryDate: null
};

const updatecbValorLiquidoGraph = (state, action) => {
    state.cbValorLiquidoGraph = action.payload;
};

const updatecbValorBrutoGraph = (state, action) => {
    state.cbValorBrutoGraph = action.payload;
};

const updatecbDailySummary = (state, action) => {
    state.cbDailySummary = action.payload;
};

const updatecbDailySummaryDisplay = (state, action) => {
    state.cbDailySummaryDisplay = action.payload;
};

const updatecbSummaryDate = (state, action) => {
    state.cbSummaryDate = action.payload;
};

const cbSlice = createSlice({
    name: "Creditos Baixados",
    initialState: initialState,
    reducers: {
        updatecbValorLiquidoGraph,
        updatecbValorBrutoGraph,
        updatecbDailySummary,
        updatecbSummaryDate,
        updatecbDailySummaryDisplay
    },
});

export const cbActions = cbSlice.actions;

export default cbSlice;
