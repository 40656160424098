import MaterialTable from "material-table";
import PrettyNumber from "components/utils/PrettyNumber";
import { putCommaCents } from "components/utils/putCommaCents";
import { LogicalText } from "components/utils/LogicalText";
const date = new Date().toJSON().slice(0, 10);

export const CreditosSummaryTable = (props) => {

    return (
        <MaterialTable
            title={props.title}
            data={props.data}
            columns={[
                {
                    title: "Valor de Face",
                    field: "valor_bruto",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) =>
                        a.valor_bruto - b.valor_bruto,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.valor_bruto}
                                type="cents"
                            />
                        );
                    },
                    exportTransformer: (row) => putCommaCents(row.valor_bruto)
                },
                {
                    title: "Valor de Compra",
                    field: "valor_liquido",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) =>
                        a.valor_liquido - b.valor_liquido,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.valor_liquido}
                                type="cents"
                            />
                        );
                    },
                    exportTransformer: (row) => putCommaCents(row.valor_liquido)
                },
                {
                    title: "Taxa",
                    field: "taxa",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) =>
                        a.taxa - b.taxa,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.taxa}
                                type="cents"
                            />
                        );
                    },
                    exportTransformer: (row) => putCommaCents(row.taxa)
                },
                {
                    title: "Dt Solicitação",
                    field: "data_solicitacao",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.tickersymbol
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "Cnpj",
                    field: "cnpj_ol",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.tickersymbol
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "Nome",
                    field: "razao_social_ol",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.tickersymbol
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                }
            ]}

            // renderSummaryRow={({ column, data }) =>
            //     column.field === "valor_bruto"
            //         ? {
            //             value: data.reduce((agg, row) => agg + row.valor_bruto, 0)
            //         }
            //         : undefined
            // }

            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                },
                pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            options={{
                filtering: true,
                search: false,
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportAllData: true,
                rowStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                    "font-size": 12,
                },
                headerStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                },
                exportButton: true,
                exportFileName: `${props.title}_${date}`
            }}
        />
    );
};

