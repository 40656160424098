import { Card, Row, CardHeader, CardBody } from "reactstrap";
import ReactDatetimeClass from "react-datetime";
const DateCard = (props) => {
    return (
        <Card className="shadow" style={{"minHeight": "100px"}}>
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                        <h2 className=" mb-0">{props.title}</h2>

                </Row>
            </CardHeader>
            <CardBody>
                <Row className="align-items-center">

                <ReactDatetimeClass 
                        inputProps={{
                            placeholder: props.placeholder
                        }} 
                        dateFormat = {props.dateFormat}
                        timeFormat = {props.timeFormat}
                        onChange = {props.onChange}
                        />

                </Row>
            </CardBody>
        </Card>
    );
};

export default DateCard;
