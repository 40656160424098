import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route,  } from "react-router-dom";
import Auth0ProviderWithHistory from "components/auth0/Auth0ProviderWithHistory";
import { Provider } from "react-redux";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import store from "store/index";
import AdminLayout from "layouts/Admin.js";
import ForceLogin from "components/auth0/ForceLogin";

ReactDOM.render(
    <BrowserRouter>
        <Auth0ProviderWithHistory>
            <ForceLogin>
                <Provider store={store}>
                        <Route
                            path="/"
                            render={(props) => <AdminLayout {...props} />}
                        />
                </Provider>
            </ForceLogin>
        </Auth0ProviderWithHistory>
    </BrowserRouter>,
    document.getElementById("root")
);
