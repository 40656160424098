const responseNotification = (
    status,
    notificationCallBack,
    authenticationCallBack,
    dispatch
) => {
    if (status == 400) {
        dispatch(
            notificationCallBack({
                status: "error",
                title: "ERROR",
                message: "A entrada foi mal formatada",
            })
        );
        dispatch(authenticationCallBack({ isAuthenticated: false }));
    } else if (status == 401) {
        dispatch(
            notificationCallBack({
                status: "error",
                title: "ERROR",
                message: "API não autorizou o acesso",
            })
        );
        dispatch(authenticationCallBack({ isAuthenticated: false }));
    } else if (status >= 500) {
        dispatch(
            notificationCallBack({
                status: "error",
                title: "ERROR",
                message: `Error interno na API, status ${status}`,
            })
        );
    } else if (status != 200) {
        dispatch(
            notificationCallBack({
                status: "error",
                title: "ERROR",
                message: `A requisição teve erro com status ${status}`,
            })
        );
    }
};
export default responseNotification;
