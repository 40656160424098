import { createSlice } from "@reduxjs/toolkit";

const showNotificationTop = (state, action) => {
    state.notificationTop = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
    };
};

const showNotificationPortfolio = (state, action) => {
    state.notificationPortfolio = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
    };
};

const showNotificationInvestors = (state, action) => {
    state.notificationInvestors = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
    };
};

const showNotificationEventCorp = (state, action) => {
    state.notificationEventCorp = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
    };
};

const initialState = {
    notificationTop: null,
    notificationPortfolio: null,
    notificationInvestors: null,
    notificationEventCorp: null,
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        showNotificationTop,
        showNotificationPortfolio,
        showNotificationInvestors,
        showNotificationEventCorp
    },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
