// reactstrap components
import { useEffect } from "react";
import { Container, Jumbotron, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import CardWithGraph from "components/Charts/CardWithGraph";
import Heatmap from "components/Charts/Heatmap";
import Plot from "react-plotly.js";
import NotificationTop from "components/notifications/NotificationTop";
import Header from "components/Headers/Header.js";
import SummaryCard from "components/CardBoards/SummaryCard";
import { CreditosSummaryTable } from "components/Table/CreditosSummaryTable";
import Loading from "components/utils/Loading";
import DateCard from "components/CardBoards/DateCard"
import { useAuth0 } from "@auth0/auth0-react";
import { fetchCreditosRejeitadosGraph, fetchCreditosRejeitadosSummary } from "store/creditos-rejeitados-actions";
import "moment/locale/pt-br"
import { crActions } from "store/creditos-rejeitados";

// core components
const CreditosRejeitados = () => {

    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.auth0.accessToken);

    const crHeatmapGraph = useSelector((state) => state.creditosRejeitados.crHeatmapGraph);

    var creditosSummary = useSelector((state) => state.creditosRejeitados.crDailySummary);
    const creditosSummaryDisplay = useSelector((state) => state.creditosRejeitados.crDailySummaryDisplay);


    if (creditosSummary) {
        creditosSummary = JSON.parse(JSON.stringify(creditosSummary)); 
    }


    const notificationTop = useSelector(
        (state) => state.notifications.notificationTop
    );

    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchCreditosRejeitadosGraph());
        }
    }, [dispatch, accessToken]);



    const changeDate = (inputDate) => {
        const data_solicitacao = inputDate._d.toJSON().slice(0, 10)

        console.log(data_solicitacao)
        dispatch(
            crActions.updatecrSummaryDate(data_solicitacao)
        );
        dispatch(crActions.updatecrDailySummaryDisplay(false))

        dispatch(fetchCreditosRejeitadosSummary())
    };

    const { isAuthenticated, isLoading, logout } = useAuth0();

    if (!isAuthenticated) {
        if (!isLoading) {
            logout();
        }
        return <Loading />;
    }


    if (crHeatmapGraph) {

        console.log(crHeatmapGraph.z)
    }
    return (
        <>

            <>

                {notificationTop && (
                    <NotificationTop
                        status={notificationTop.status}
                        title={notificationTop.title}
                        message={notificationTop.message}
                    />
                )}
                <Header
                    container={
                        <Container fluid>
                            <div className="header-body">
                                {/* Card stats */}

                            </div>
                        </Container>
                    }
                />



                {/* Page content */}

                <Container className="mt--7" fluid>

                    <Jumbotron>

                        <h1> Creditos Rejeitados</h1>

                        <p align="justify">
                            Créditos que foram rejeitados.
                        </p>

                    </Jumbotron>

                </Container>

                {/* {!qcaTable && <Loading />} */}
                <Container className="mt--7" fluid>


                    {crHeatmapGraph && (

                        <CardWithGraph
                            title="Dias com créditos rejeitados"
                            graph={

                                <Heatmap data={crHeatmapGraph} />

                            }
                        />)}

                    <Row className="mt-5"></Row>
                    <DateCard
                        title="Data de solicitação para resumo"
                        placeholder="Data de solicitação"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onChange={changeDate}
                    />

                    {(creditosSummary && !creditosSummaryDisplay) && <Loading />}
                    {(creditosSummary && creditosSummaryDisplay) && (<CreditosSummaryTable title="Créditos Baixados por OL" data={creditosSummary} />)}


                </Container>
            </>




        </>
    );
};

export default CreditosRejeitados;
