import { notificationsActions } from "./notifications";
import { cbActions } from "./creditos-baixados"
import { auth0SliceActions } from "./auth0";
import { decompressJsonFromFetch } from "components/utils/downloadFile";
import responseNotification from "components/utils/responseNotification";
import store from "store";

export const fetchCreditosBaixadosSummary = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const cbSummaryDate = store.getState().creditosBaixados.cbSummaryDate;

            const payloadDailySummary = {
                ping: "false",
                type: "baixados",
                data_solicitacao: cbSummaryDate
            };

            const cbDailySummaryData = await fetchData(accessToken, payloadDailySummary, "olcreditosdate")
            const cbDailySummary = await decompressJsonFromFetch(cbDailySummaryData.rawJSONCompleteTable);


            dispatch(cbActions.updatecbDailySummary(cbDailySummary));
            dispatch(cbActions.updatecbDailySummaryDisplay(true))
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};


export const fetchCreditosBaixadosGraph = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const payloadGraph = {
                ping: "false",
                type: "baixados"
            };

            const cbGraphData = await fetchData(accessToken, payloadGraph, "graphcreditosshare");
           
            const cbGraphValorLiquido = await decompressJsonFromFetch(cbGraphData.rawJSONValorLiquidoGraph);
            const cbGraphValorBruto = await decompressJsonFromFetch(cbGraphData.rawJSONValorBrutoGraph);

           


            dispatch(cbActions.updatecbValorLiquidoGraph(cbGraphValorLiquido));
            dispatch(cbActions.updatecbValorBrutoGraph(cbGraphValorBruto));
           
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};
