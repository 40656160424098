// reactstrap components
import { Container, Jumbotron, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import NotificationTop from "components/notifications/NotificationTop";
import Header from "components/Headers/Header.js";
import SummaryCard from "components/CardBoards/SummaryCard";
import Loading from "components/utils/Loading";
import ReceberOlTable from "components/Table/ReceberOl";
import { putCommaCents } from "components/utils/putCommaCents";


const cardArray = (itemArray) => {

    if (itemArray == null)
        return (<></>)


    const result = itemArray.map((e) => {
        return (
            <Col lg="4" xl="4">
                <SummaryCard
                    title={`Vencimento: ${e.data_vencimento}`}
                    icon="fas fa-money-bill-wave"
                    absoluteValue={e.valor_bruto}
                    absoluteType="cents"
                />
            </Col>

        )
    })

    return (result)

}
// core components
const Vencimento = () => {

    const notificationTop = useSelector(
        (state) => state.notifications.notificationTop
    );

    var qcaTable = (useSelector((state) => state.quinzenaCA.qcaTable));
    const qcaSummary = (useSelector((state) => state.quinzenaCA.qcaSummary));

    if (qcaTable) {
        qcaTable = JSON.parse(JSON.stringify(qcaTable)); 
    }

    const cards = cardArray(qcaSummary)

    return (
        <>

            <>

                <Header
                />



                {/* Page content */}


                <Container className="mt--7" fluid>


                <Jumbotron>

                    <h1> Creditos a abertos a vencer</h1>

                    <p align="justify">
                        Créditos abertos separados por data de vencimento.
                    </p>

                </Jumbotron>

                </Container>

                {!qcaTable && <Loading />}
                <Container className="mt--7" fluid>
                    {qcaSummary && cards}
                    <Row className="mt-5"></Row>
                    {qcaTable && (<ReceberOlTable title="Resumo Quinzena Creditos Abertos" data={qcaTable} />)}


                </Container>
            </>




        </>
    );
};

export default Vencimento;
