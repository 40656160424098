import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    helloString: null
};

const updateHelloString = (state, action) => {
    state.helloString = action.payload;
};

const helloSlice = createSlice({
    name: "auth0",
    initialState: initialState,
    reducers: {
        updateHelloString,
    },
});

export const helloActions = helloSlice.actions;

export default helloSlice;
