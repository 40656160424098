export const putCommaCents = (number) => {
    const size = ('' + number).length;

    if (size == 1) {
        return ('0,0' + number);
    } else if (size == 2) {
        return('0,' + number);
    } else if (size >= 3) {
        const a = ('' +number);
        const position = size -2;

        return([a.slice(0, position), ',', a.slice(position)].join(''))
    } else {
        throw new Error("putCommaCents");
    }

}