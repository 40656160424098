import { notificationsActions } from "./notifications";
import { ccActions } from "./creditos-comprados"
import { auth0SliceActions } from "./auth0";
import { decompressJsonFromFetch } from "components/utils/downloadFile";
import responseNotification from "components/utils/responseNotification";
import store from "store";

export const fetchCreditosCompradosSummary = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const ccSummaryDate = store.getState().creditosComprados.ccSummaryDate;

            const payloadDailySummary = {
                ping: "false",
                type: "comprados",
                data_solicitacao: ccSummaryDate
            };

            const ccDailySummaryData = await fetchData(accessToken, payloadDailySummary, "olcreditosdate")
            const ccDailySummary = await decompressJsonFromFetch(ccDailySummaryData.rawJSONCompleteTable);


            dispatch(ccActions.updateccDailySummary(ccDailySummary));
            dispatch(ccActions.updateccDailySummaryDisplay(true))
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};


export const fetchCreditosCompradosGraph = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        

        try {
            const accessToken = store.getState().auth0.accessToken;
            const payloadGraph = {
                ping: "false",
                type: "comprados"
            };

            const ccGraphData = await fetchData(accessToken, payloadGraph, "graphcreditosshare");
            const ccGraphCountData = await fetchData(accessToken, payloadGraph, "graphcreditoscountshare");
           
            const ccGraphValorLiquido = await decompressJsonFromFetch(ccGraphData.rawJSONValorLiquidoGraph);
            const ccGraphValorBruto = await decompressJsonFromFetch(ccGraphData.rawJSONValorBrutoGraph);

            const ccGraphAntecipacoesUnicasOl = await decompressJsonFromFetch(ccGraphCountData.rawJSONAntecipacoesUnicasOlGraph);
            const ccGraphAntecipacoesUnicasTotalGraph = await decompressJsonFromFetch(ccGraphCountData.rawJSONAntecipacoesUnicasTotalGraph);



            dispatch(ccActions.updateccValorLiquidoGraph(ccGraphValorLiquido));
            dispatch(ccActions.updateccValorBrutoGraph(ccGraphValorBruto));

            dispatch(ccActions.updateccAntecipacoesUnicasOlGraph(ccGraphAntecipacoesUnicasOl));
            dispatch(ccActions.updateccAntecipacoesUnicasTotalGraph(ccGraphAntecipacoesUnicasTotalGraph));
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};
