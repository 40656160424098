import { notificationsActions } from "./notifications";
import { qcaActions } from "./quinzena-creditos-abertos";
import { auth0SliceActions } from "./auth0";
import { decompressJsonFromFetch } from "components/utils/downloadFile";
import responseNotification from "components/utils/responseNotification";
import store from "store";

export const fetchQuinzenaCA = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + "quinzenacreditosabertos",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();


            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const payloadToPost = {
                ping: "false"
            };
            const data = await fetchData(accessToken, payloadToPost);


            var qcaTable = await decompressJsonFromFetch(data.rawJSONCompleteTable);

            var qcaSummary = await decompressJsonFromFetch(data.rawJSONReumoQuinzena);


            dispatch(qcaActions.updateQcaTable(qcaTable));
            dispatch(qcaActions.updateQcaSummary(qcaSummary));

            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};
