 
import classes from './NotificationTop.module.css';

const NotificationTop = (props) => {
  let specialClasses = '';

  if (props.status === 'error') {
    specialClasses = classes.error;
  }
  if (props.status === 'success') {
    specialClasses = classes.success;
  }

  const cssClasses = `${classes.notification} ${specialClasses}`;

  return (
    <section className={cssClasses}>
      <h2>{props.title}</h2>
      <h3>{props.message}</h3>
    </section>
  );
};

export default NotificationTop;