import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/utils/Loading";

const ForceLogin = (props) => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    if (!isAuthenticated) {
        if (!isLoading) {
            loginWithRedirect();
        }
        return <Loading />;
    } else {
        return <>{props.children}</>;
    }
};

export default ForceLogin;
