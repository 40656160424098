import {Row, Col } from "reactstrap";

const RowWithTwoCols = (props) => {
    return (
        <Row className="mt-5">
            <Col className="" xl={props.colLeftSize}>
                {props.leftComponent}
            </Col>
            <Col className="" xl={props.colRightSize}>
                {props.rightComponent}
            </Col>
        </Row>
    );
};

export default RowWithTwoCols;
