import { notificationsActions } from "./notifications";
import { crActions } from "./creditos-rejeitados"
import { auth0SliceActions } from "./auth0";
import { decompressJsonFromFetch } from "components/utils/downloadFile";
import responseNotification from "components/utils/responseNotification";
import store from "store";

export const fetchCreditosRejeitadosSummary = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const crSummaryDate = store.getState().creditosRejeitados.crSummaryDate;

            const payloadDailySummary = {
                ping: "false",
                type: "rejeitados",
                data_solicitacao: crSummaryDate
            };

            const crDailySummaryData = await fetchData(accessToken, payloadDailySummary, "olcreditosdate")
            const crDailySummary = await decompressJsonFromFetch(crDailySummaryData.rawJSONCompleteTable);


            dispatch(crActions.updatecrDailySummary(crDailySummary));
            dispatch(crActions.updatecrDailySummaryDisplay(true))
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};


export const fetchCreditosRejeitadosGraph = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload, endpoint) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + endpoint,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();
            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const payloadGraph = {
                ping: "false"
            };

            const crGraphData = await fetchData(accessToken, payloadGraph, "heatmaprejeitados");
           
            const crGraph = await decompressJsonFromFetch(crGraphData.rawJSONHeatmapRejected);

           


            dispatch(crActions.updatecrHeatmapoGraph(crGraph));
           
            
            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API teve retorno",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};
