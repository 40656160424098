import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    qcaTable: null,
    qcaSummary: null
};

const updateQcaTable = (state, action) => {
    state.qcaTable = action.payload;
};

const updateQcaSummary = (state, action) => {
    state.qcaSummary = action.payload;
};

const qcaSlice = createSlice({
    name: "Quinzena Creditos Abertos",
    initialState: initialState,
    reducers: {
        updateQcaTable,
        updateQcaSummary
    },
});

export const qcaActions = qcaSlice.actions;

export default qcaSlice;
