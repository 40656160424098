import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    accessToken: '',
    isAuthenticated: true
};

const updateAccessToken = (state, action) => {
    state.accessToken = action.payload.accessToken;
};
const updateIsAuthenticated = (state, action) => {
    state.isAuthenticated = action.payload.isAuthenticated;
};

const auth0Slice = createSlice({
    name: "auth0",
    initialState: initialState,
    reducers: {
        updateAccessToken,
        updateIsAuthenticated
    },
});

export const auth0SliceActions = auth0Slice.actions;

export default auth0Slice;
