// reactstrap components
import { useEffect } from "react";
import { Container, Jumbotron, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import CardWithGraph from "components/Charts/CardWithGraph";
import LineChart from "components/Charts/LineChart";
import NotificationTop from "components/notifications/NotificationTop";
import Header from "components/Headers/Header.js";
import SummaryCard from "components/CardBoards/SummaryCard";
import { CreditosSummaryTable } from "components/Table/CreditosSummaryTable";
import Loading from "components/utils/Loading";
import DateCard from "components/CardBoards/DateCard";
import { ccActions } from "store/creditos-comprados";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchCreditosCompradosGraph, fetchCreditosCompradosSummary } from "store/creditos-comprados-actions";
import "moment/locale/pt-br"

// core components
const CreditosComprados = () => {

    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.auth0.accessToken);

    const ccValorBrutoGraph = useSelector((state) => state.creditosComprados.ccValorBrutoGraph);
    const ccValorLiquidoGraph = useSelector((state) => state.creditosComprados.ccValorLiquidoGraph);

    const ccAntecipacoesUnicasOlGraph = useSelector((state) => state.creditosComprados.ccAntecipacoesUnicasOlGraph);
    const ccAntecipacoesUnicasTotalGraph = useSelector((state) => state.creditosComprados.ccAntecipacoesUnicasTotalGraph);

    var creditosSummary = useSelector((state) => state.creditosComprados.ccDailySummary);
    const creditosSummaryDisplay = useSelector((state) => state.creditosComprados.ccDailySummaryDisplay);


    if (creditosSummary) {
        creditosSummary = JSON.parse(JSON.stringify(creditosSummary));
    }

    const notificationTop = useSelector(
        (state) => state.notifications.notificationTop
    );

    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchCreditosCompradosGraph());
        }
    }, [dispatch, accessToken]);



    const changeDate = (inputDate) => {
        const data_solicitacao = inputDate._d.toJSON().slice(0, 10)

        console.log(data_solicitacao)
        dispatch(
            ccActions.updateccSummaryDate(data_solicitacao)
        );
        dispatch(ccActions.updateccDailySummaryDisplay(false))

        dispatch(fetchCreditosCompradosSummary())
    };


    const { isAuthenticated, isLoading, logout } = useAuth0();

    if (!isAuthenticated) {
        if (!isLoading) {
            logout();
        }
        return <Loading />;
    }


    console.log(creditosSummaryDisplay)
    return (
        <>

            <>

                {notificationTop && (
                    <NotificationTop
                        status={notificationTop.status}
                        title={notificationTop.title}
                        message={notificationTop.message}
                    />
                )}
                <Header
                    container={
                        <Container fluid>
                            <div className="header-body">
                                {/* Card stats */}

                            </div>
                        </Container>
                    }
                />



                {/* Page content */}

                <Container className="mt--7" fluid>

                    <Jumbotron>

                        <h1> Creditos Comprados</h1>

                        <p align="justify">
                            Créditos  que já passaram pelo processo de compra junto com a Administradora. Neste caso, créditos baixados estão incluídos.
                        </p>

                    </Jumbotron>

                </Container>
                {/* {!qcaTable && <Loading />} */}
                <Container className="mt--7" fluid>


                    {ccValorBrutoGraph && ccValorLiquidoGraph && (

                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Share por OL: Valor Bruto"
                                    graph={
                                        <LineChart
                                            data={
                                                ccValorBrutoGraph
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Share por OL: Valor Liquido"
                                    graph={
                                        <LineChart
                                            data={
                                                ccValorLiquidoGraph
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}


                    {ccAntecipacoesUnicasOlGraph && ccAntecipacoesUnicasTotalGraph && (

                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Antecipações únicas por OL"
                                    graph={
                                        <LineChart
                                            data={
                                                ccAntecipacoesUnicasOlGraph
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Antecipações únicas total"
                                    graph={
                                        <LineChart
                                            data={
                                                ccAntecipacoesUnicasTotalGraph
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                    <Row className="mt-5"></Row>
                    <DateCard
                        title="Data de solicitação para resumo"
                        placeholder="Data de solicitação"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onChange={changeDate}
                    />

                    {(creditosSummary && !creditosSummaryDisplay) && <Loading />}
                    {(creditosSummary && creditosSummaryDisplay) && (<CreditosSummaryTable title="Créditos Comprados por OL" data={creditosSummary} />)}


                </Container>
            </>




        </>
    );
};

export default CreditosComprados;
