import React from "react";
import styles from "components/utils/Loading.module.css";

const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Loading = () => (

    <div >
      <img className={styles.loading} src={loadingImg} alt="Loading..." />
    </div>
);

export default Loading;
