import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    crHeatmapGraph: null,
    crDailySummary: null,
    crDailySummaryDisplay: false,
    crSummaryDate: null
};

const updatecrHeatmapoGraph = (state, action) => {
    state.crHeatmapGraph = action.payload;
};


const updatecrDailySummary = (state, action) => {
    state.crDailySummary = action.payload;
};

const updatecrDailySummaryDisplay = (state, action) => {
    state.crDailySummaryDisplay = action.payload;
};

const updatecrSummaryDate = (state, action) => {
    state.crSummaryDate = action.payload;
};

const crSlice = createSlice({
    name: "Creditos Rejeitados",
    initialState: initialState,
    reducers: {
        updatecrHeatmapoGraph,
        updatecrDailySummary,
        updatecrSummaryDate,
        updatecrDailySummaryDisplay
    },
});

export const crActions = crSlice.actions;

export default crSlice;
