import PrettyNumber from "components/utils/PrettyNumber";
import { Row, Col, CardBody, Card, CardTitle } from "reactstrap";

const SummaryCard = (props) => {
    const isPercentNegative = props.percentValue < 0;

    return (
        <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
                <Row>
                    <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            {props.title}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            <PrettyNumber
                                number={props.absoluteValue}
                                type={props.absoluteType}
                            />
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className={`${props.icon}`} />
                        </div>
                    </Col>
                </Row>
                <p className="mt-3 mb-0 font-weight-bold text-sm">
                    {props.percentValue &&
                        <>
                            <PrettyNumber
                                number={props.percentValue}
                                type="percent"
                            />
                            {" "}
                            <span className="text-nowrap">
                                {" "}
                                {isPercentNegative && "=["}
                                {!isPercentNegative && "=]"}{" "}
                            </span>
                        </>

                    }

                </p>
            </CardBody>
        </Card>
    );
};

export default SummaryCard;
