// reactstrap components
import { Container, Jumbotron } from "reactstrap";
import { useSelector } from "react-redux";
import Header from "components/Headers/Header.js";
// core components
const Entrypoint = () => {


    // get auth0 token at this page
    const accessToken = useSelector((state) => state.auth0.accessToken);

    // const notificationTop = useSelector(
    //     (state) => state.notifications.notificationTop
    // );

    // const qcaJsonString = JSON.stringify(useSelector((state) => state.quinzenaCA.qcaJson));



    return (
        <>


            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>

                <Jumbotron>

                    <h1> Bem vindo</h1>

                    <p align="justify">
                        Esta é a dashboard de acompanhamento dos Fundos relacionados a LEVO. Abaixo uma breve explicação de cada componente
                    </p>

                    <ul>
                        <li>Visão geral: Aqui poderá observar informações Macro em relação a cada aba.</li>
                        <li>Créditos Comprados: Informações agregadas por OL, referente a todo crédito comprado pelo fundo.</li>
                        <li>Créditos Baixados: Informações agregadas por OL, referente a todo crédito já com status de baixa.</li>
                        <li>Créditos Rejeitados: Informações agregadas por OL, referente a todo crédito que teve a compra rejeitada pelo fundo.</li>
                        <li>Vencimento: Informações agregadas por OL, referente a todo crédito que ainda está aberto, com data a vencer.</li>
                    </ul>


                </Jumbotron>

            </Container>
        </>
    );
};

export default Entrypoint;
