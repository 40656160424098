/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import CreditosComprados from "views/pages/CreditosComprados";
import Entrypoint from "views/pages/Entrypoint.js";
import Vencimento from "views/pages/Vencimento.js";
import CreditosBaixados from "views/pages/CreditosBaixados";
import CreditosRejeitados from "views/pages/CreditosRejeitados";

var routes = [
    {
        path: "/index",
        name: "Início",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: Entrypoint,
        layout: "/index",
    },

    {
        path: "/creditos_comprados",
        name: "Créditos Comprados",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: CreditosComprados,
        layout: "/index",
    },

    {
        path: "/creditos_baixados",
        name: "Créditos Baixados",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: CreditosBaixados,
        layout: "/index",
    },

    {
        path: "/creditos_rejeitados",
        name: "Créditos Rejeitados",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: CreditosRejeitados,
        layout: "/index",
    },

    {
        path: "/vencimento",
        name: "Vencimento",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: Vencimento,
        layout: "/index",
    }
];
export default routes;
