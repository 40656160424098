export const LogicalText = (props) => {

    if (props.text == true) {
        return (

            <span className="text-success mr-2">
                {`${props.text}`}
            </span>
        );
    } else if (props.text == false) {

        return (
            <span className="text-danger mr-2">
                {`${props.text}`}
            </span>
        );
    } else {
        return (
            <>
                {`${props.text}`}
            </>
        );
    }

}