import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    ccValorLiquidoGraph: null,
    ccValorBrutoGraph: null,    
    ccAntecipacoesUnicasOlGraph: null,
    ccAntecipacoesUnicasTotalGraph: null,
    ccDailySummary: null,
    ccDailySummaryDisplay: false,
    ccSummaryDate: null
};

const updateccValorLiquidoGraph = (state, action) => {
    state.ccValorLiquidoGraph = action.payload;
};

const updateccValorBrutoGraph = (state, action) => {
    state.ccValorBrutoGraph = action.payload;
};


const updateccAntecipacoesUnicasOlGraph = (state, action) => {
    state.ccAntecipacoesUnicasOlGraph = action.payload;
};

const updateccAntecipacoesUnicasTotalGraph = (state, action) => {
    state.ccAntecipacoesUnicasTotalGraph = action.payload;
};


const updateccDailySummary = (state, action) => {
    state.ccDailySummary = action.payload;
};

const updateccDailySummaryDisplay = (state, action) => {
    state.ccDailySummaryDisplay = action.payload;
};

const updateccSummaryDate = (state, action) => {
    state.ccSummaryDate = action.payload;
};

const ccSlice = createSlice({
    name: "Creditos Comprados",
    initialState: initialState,
    reducers: {
        updateccValorLiquidoGraph,
        updateccValorBrutoGraph,
        updateccAntecipacoesUnicasOlGraph,
        updateccAntecipacoesUnicasTotalGraph,
        updateccDailySummary,
        updateccSummaryDate,
        updateccDailySummaryDisplay
    },
});

export const ccActions = ccSlice.actions;

export default ccSlice;
